import React from "react";
import wp from "./wp.png";

const Poster = () => {
  return (
    <div className="Poster">
      <img src={wp} className="posterImg" alt="poster" />
    </div>
  );
};

export default Poster;
