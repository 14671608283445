import { Button, ButtonGroup, Box } from "@material-ui/core";
import React from "react";
import Countdown from "react-countdown";
import eng1 from "./eng1.png";
import { Link } from "react-router-dom";

import { SLACK_URL, NavItem } from "./App";

const YOUTUBE_LINK = "https://youtu.be/-NWS4hNbJ7c";
const SHOW_EARLY = true;

const Home = ({
  navItems,
  onLogoClick,
}: {
  navItems: NavItem[];
  onLogoClick: () => void;
}) => {
  return (
    <div className="Home">
      <img src={eng1} className="logo" alt="logo" onClick={onLogoClick} />
      <h1>
        TechLab<sup>20</sup>
      </h1>
      <h2>See you next time!</h2>

      <p>
        <ButtonGroup>
          {navItems.map(({ title, url, external }, i) => {
            return (
              <Box m={2} key={i}>
                {external ? (
                  <Button href={url} variant="contained" color="primary">
                    {title}
                  </Button>
                ) : (
                  <Button
                    component={Link}
                    to={url}
                    variant="contained"
                    color="primary"
                  >
                    {title}
                  </Button>
                )}
              </Box>
            );
          })}
        </ButtonGroup>
      </p>
      <div>
        <a href={SLACK_URL}>
          <code>Slack: #techlab</code>
        </a>
      </div>
    </div>
  );
};

export default Home;
