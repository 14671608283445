import React from "react";

const About = () => {
  return (
    <div className="About" style={{ margin: 200, lineHeight: 1.4 }}>
      <h3>Welcome!</h3>
      <p>
        <strong>TechLab</strong> is Red Badger&apos;s in-house engineering
        conference. TechLab 2020 will mark the third iteration of the event.
      </p>
      <p>
        As a consultancy, one of our great strengths comes from being able to
        draw upon a huge wealth of talent and experience, and in order to
        capitalise on that we need to have the ability to share that wealth of
        experience with each other!
      </p>
      <p>
        TechLab provides us this opportunity: for everyone in the engineering
        discipline to take some time together, to share learnings from projects,
        teach each other about cool or upcoming technologies / ways of working /
        ideas, and to get to know each other better.
      </p>
      <p>
        It&apos;s been a little over two years since our previous event, so this
        time our primary focus will be on <strong>reconnecting</strong>:
        we&apos;ll be taking a look at what we&apos;ve been up to, reflecting on
        learnings and accomplishments from our recent projects, and looking to
        what technology may lie ahead for us all in the future.
      </p>
      <p>
        Whilst this year&apos;s event will be fully remote, we want to make
        things feel as close to a real conference as possible. As such,
        we&apos;ve tried to think of opportunities to add as much interactivity
        as possible, and think of ways that we can actually use the fact
        we&apos;re all in front of our laptops as an advantage.
      </p>
      <p>
        We have an action-packed day lined up, and we hope you&apos;ll enjoy it.
        Head over to the Agenda page to find out more
      </p>
      <p>
        PS: there are also some goodies headed to all attendees in the post 😉
      </p>
    </div>
  );
};

export default About;
