import { Button } from "@material-ui/core";
import React from "react";
import Countdown from "react-countdown";

const GATHER_LINK = "https://gather.town/app/JRu4517hgQyI7r9F/techlab2020";
const SHOW_EARLY = false;

const VirtualSocial = () => {
  return (
    <div className="VirtualSocial">
      <h1>Virtual Social!</h1>
      <h2>Hosted on Gather</h2>
      See you next time!
    </div>
  );
};

export default VirtualSocial;
