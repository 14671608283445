import React from "react";
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const data = [
  ["10.00", "➡️ Intro", "-"],
  ["10.10", "📚 Project talk: Nando’s loyalty", "Tom G"],
  [
    "10.30",
    "⚡ Lightning talk: How teaching code to people with disabilities changed the way I see programming",
    "Pedro M",
  ],
  ["10.40", "💻 Breakout", "-"],
  ["11.00", "🎤 Panel", "TDP, hosted by Sean G"],
  ["11.30", "➡️ Break", "-"],
  ["11.40", "📚 Project talk: MHRA", "Robin JK"],
  ["12.00", "➡️ Break", "-"],
  ["12.10", "📚 Project talk: LME", "Tomasz S"],
  ["12.30", "🍛 Lunch", "-"],
  ["13.30", "👨‍🔬 Keynote", "Stu H"],
  ["14.30", "➡️ Break", "-"],
  ["14.40", "📚 Project talk: FutureNHS", "David L"],
  ["15.00", "⚡ Lightning talk: Throwing Shade(rs)", "Simon A"],
  ["15.10", "💻 Breakout", "-"],
  ["15.30", "📚 Project talk: Nando’s Jenga", "Jenny S & Graeme C"],
  [
    "15.50",
    "⚡ Lightning talk: Genetic Algorithms in Rust, in 3 hours, in 5 minutes",
    "Viktor C",
  ],
  ["16.00", "➡️ Break", "-"],
  ["16.10", "🎯 Tech Radar: What’s Hot/What’s Not", "Rui R"],
  ["16.30", "📚 Project talk: Valloop", "Aayush A"],
  ["16.50", "➡️ Wrap-up", "-"],
  ["17.00", "🍷 Virtual Social!", "-"],
];

const accordionData: [string, string][] = [
  [
    "Project talks",
    "These will give an overview of what a project is, and dive in to some of the tech involved. Q&A will run on Slido and in Slack!",
  ],
  [
    "Lightning talks",
    "Rapid, five minute talks designed to give a high-level overview and spark interest in a particular topic. Q&A will run on Slido and in Slack!",
  ],
  [
    "Panel",
    "A round table discussion with the Technology Discipline Panel (Tech Directors and Chief Scientist). Submit questions on Slido and in Slack!",
  ],
  [
    "Breakouts",
    "We have a (fun) exercise lined up which you can work on in each of your groups!",
  ],
  ["Keynote", "Top secret feature presentation"],
  [
    "Tech Radar",
    "An opportunity to canvas Red Badger's opinion for what's hot and what's not when it comes to technologies, ways of working, philosophies, and more.",
  ],
  [
    "Virtual Social",
    "At the end of the day, we'll finish with a virtual social organised using an app called Gather. Head over to the page to find the link!",
  ],
];

const Agenda = () => {
  return (
    <div>
      <h2>Overview</h2>
      <table style={{ borderCollapse: "collapse", marginBottom: "20px" }}>
        <thead>
          <tr>
            <th style={{ textAlign: "left", padding: "8px 0" }}>Time</th>
            <th style={{ textAlign: "left", padding: "0 10px" }}>Event</th>
            <th style={{ textAlign: "right" }}>Presenter</th>
          </tr>
        </thead>
        <tbody>
          {data.map((agendaItem) => {
            return (
              <tr key={agendaItem[1]}>
                <td style={{ textAlign: "left", padding: "8px 0" }}>
                  <code>{agendaItem[0]}</code>
                </td>
                <td style={{ textAlign: "left", padding: "0 10px" }}>
                  {agendaItem[1]}
                </td>
                <td style={{ textAlign: "right" }}>{agendaItem[2]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <h2>Info</h2>
      <div style={{ marginBottom: "20px" }}>
        {accordionData.map((accordionItem, i) => {
          return (
            <Accordion key={i}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{accordionItem[0]}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{accordionItem[1]}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </div>
  );
};

export default Agenda;
