import { Button } from "@material-ui/core";
import React from "react";

const DATA = [
  [
    "Babbage",
    "https://meet.google.com/msy-eorm-tht",
    "https://codepen.io/samwhitebadger/collab/727fff5f14f8b37449ecd8b715ec14ba",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/Charles_Babbage_-_1860.jpg/220px-Charles_Babbage_-_1860.jpg",
    'Considered by some to be "father of the computer", Babbage is credited with inventing the first mechanical computer that eventually led to more complex electronic designs, though all the essential ideas of modern computers are to be found in Babbage\'s Analytical Engine.',
    "Matt Paul, Declan Slevin, Xavier Delamotte, Abi Coe, Jamie Potter, Edmund Compton",
  ],
  [
    "Bartik",
    "https://meet.google.com/mtd-ivux-rze",
    "https://codepen.io/samwhitebadger/collab/ba118f0457e795e1fbf6457a6f8b4524",
    "https://upload.wikimedia.org/wikipedia/en/1/19/Jean_Bartik.jpg",
    "Working on ENIAC, Bartik and other women developed subroutines, nesting, and other fundamental programming techniques, and arguably invented the discipline of programming digital computers. Bartik and the other ENIAC female programmers learned to physically modify the machine, moving switches and rerouting cables, in order to program it.",
    "Monika Ferencz-Szabo, Dan Train, Jenny Sharps, Peter Jones, Tomasz Szarzynski, Christopher Brookes",
  ],
  [
    "Boole",
    "https://meet.google.com/ykp-juzc-auv",
    "https://codepen.io/samwhitebadger/collab/56cfe78ccf35899eced44a7e9c87f7c5",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/George_Boole_color.jpg/220px-George_Boole_color.jpg",
    "Boolean algebra was introduced by George Boole in his first book The Mathematical Analysis of Logic (1847). Boolean algebra has been fundamental in the development of digital electronics, and is provided for in all modern programming languages. It is also used in set theory and statistics.",
    "Tom Grimley, Sam Rowe, Alex Brazier, Robin James Kerrison, Duncan MacKinnon, Chloe Smith, Sam White",
  ],
  [
    "Church",
    "https://meet.google.com/vmd-wgip-wpd",
    "https://codepen.io/samwhitebadger/collab/81ed04b6d11b8257a4546bb03f0d6086",
    "https://upload.wikimedia.org/wikipedia/en/thumb/a/a6/Alonzo_Church.jpg/220px-Alonzo_Church.jpg",
    "Lambda calculus emerged in Church's 1936 paper showing the unsolvability of the Entscheidungsproblem. This preceded Alan Turing's work on the same problem, which also demonstrated the existence of a problem unsolvable by mechanical means. The efforts for automatically generating a controller implementation from specifications originates from his ideas. The lambda calculus influenced the design of the LISP programming language and functional programming languages in general. The Church encoding is named in his honor.",
    "Sam Smith, Tornike Keburia, Stuart Harris, Simon Ashbery, Anubha Singh, David Laban, David Thompson",
  ],
  [
    "Hopper",
    "https://meet.google.com/vqo-mark-zof",
    "https://codepen.io/samwhitebadger/collab/190058f0ff31b071a9bb077bfdbb2416",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ad/Commodore_Grace_M._Hopper%2C_USN_%28covered%29.jpg/220px-Commodore_Grace_M._Hopper%2C_USN_%28covered%29.jpg",
    "One of the first programmers of the Harvard Mark I computer, she was a pioneer of computer programming who invented one of the first linkers. Hopper was the first to devise the theory of machine-independent programming languages, and the FLOW-MATIC programming language she created using this theory was later extended to create COBOL, an early high-level programming language still in use today.",
    "Viktor Charypar, Jack Rehaag, Rane Gowan, Aayush Attri, Dimple Patel, Jack Davis",
  ],
  [
    "Knuth",
    "https://meet.google.com/zwk-fywa-okn",
    "https://codepen.io/samwhitebadger/collab/c218fee052d4c2972dc83b4de1c8ffde",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/KnuthAtOpenContentAlliance.jpg/192px-KnuthAtOpenContentAlliance.jpg",
    'Donald Knuth is the 1974 recipient of the ACM Turing Award, informally considered the Nobel Prize of computer science. Knuth has been called the "father of the analysis of algorithms". He is the author of the multi-volume work The Art of Computer Programming. He contributed to the development of the rigorous analysis of the computational complexity of algorithms and systematized formal mathematical techniques for it.',
    "Greg Dorward, Sam Taylor, Tracy Wu, Jay Freestone, Reece Lucas, Will Eldrid-Otterburn",
  ],
  [
    "Liskov",
    "https://meet.google.com/bda-jveg-rko",
    "https://codepen.io/samwhitebadger/collab/106a882f673c7e783006964a88a512c3",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Barbara_Liskov_MIT_computer_scientist_2010.jpg/220px-Barbara_Liskov_MIT_computer_scientist_2010.jpg",
    "Barbara Liskov was one of the first women to be granted a doctorate in computer science in the United States and is a Turing Award winner who developed the Liskov substitution principle. She leads the Programming Methodology Group at MIT, with a current research focus in Byzantine fault tolerance and distributed computing.",
    "Haro Lee, Tom Barwick, Michelle Vidler, Joseph Popoola, Tim Lee, Saverio Tassinari",
  ],
  [
    "Lovelace",
    "https://meet.google.com/bwb-dzmq-rfc",
    "https://codepen.io/samwhitebadger/collab/34b5d8614a2cb2b64b7c1ca22558d8f0",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Ada_Lovelace_portrait.jpg/220px-Ada_Lovelace_portrait.jpg",
    'Ada Lovelace is believed by some to be the first to recognise that the machine had applications beyond pure calculation, and to have published the first algorithm intended to be carried out by such a machine. As a result, she is often regarded as the first to recognise the full potential of computers and as one of the first to be a computer programmer. Her mindset of "poetical science" led her to ask questions about the Analytical Engine (as shown in her notes) examining how individuals and society relate to technology as a collaborative tool.',
    "Samera Butt, Pedro Martin Valera, Jon Yardley, James Dockeray, Graeme Coupar, Rui Ramos, Chris Whealey",
  ],
];

const Breakouts = () => {
  return (
    <div className="About" style={{ margin: 200, lineHeight: 1.4 }}>
      <h1>Breakouts</h1>
      <p>The breakout activity will take place in groups.</p>
      <p>
        Check the Poster or your lanyard to find out which group you&apos;re in.
      </p>
      <h2>The Challenge</h2>
      <p>
        Create the best visualisation of the Martial Robots on their journey
        around (or off!) Mars. Your codepen will have a starter kit which
        outputs a list of all positions at each turn.
      </p>
      <hr />
      {DATA.map((group) => {
        return (
          <>
            <h2>{group[0]}</h2>
            <img src={group[3]} />
            <blockquote>{group[4]}</blockquote>
          </>
        );
      })}
    </div>
  );
};

export default Breakouts;
