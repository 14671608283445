import React, { useEffect, useState } from "react";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import rblogo from "./rblogo.png";

import Home from "./Home";
import Agenda from "./Agenda";
import Poster from "./Poster";
import About from "./About";
import VirtualSocial from "./VirtualSocial";
import Breakouts from "./Breakouts";

export const SLACK_URL = "https://redbadger.slack.com/archives/C01FF75LG4V";

export interface NavItem {
  title: string;
  url: string;
  external: boolean;
}

const homeNavItem = { title: "🏡 Home", url: "/", external: false };
const aboutNavItem = { title: "ℹ️ About", url: "/about", external: false };
const agendaNavItem = { title: "🗓 Agenda", url: "/agenda", external: false };

const breakoutsNavItem = {
  title: "🧑‍💻 Breakouts",
  url: "/breakouts",
  external: false,
};

const virtualSocialNavItem = {
  title: "🍷 Virtual Social",
  url: "/virtual-social",
  external: false,
};
const posterNavItem = {
  title: "🖼  Poster",
  url: "/poster",
  external: false,
};

const slidoNavItem = {
  title: "⁉️ Slido",
  url: "https://app.sli.do/event/pzish5sc",
  external: true,
};

const slackNavItem = {
  title: "👨‍💻 Slack: #techlab",
  url: SLACK_URL,
  external: true,
};

const NAV_ITEMS: NavItem[] = [
  homeNavItem,
  aboutNavItem,
  agendaNavItem,
  breakoutsNavItem,
  posterNavItem,
  virtualSocialNavItem,
  slidoNavItem,
  slackNavItem,
];

const HOME_NAV_ITEMS: NavItem[] = [
  aboutNavItem,
  agendaNavItem,
  breakoutsNavItem,
  posterNavItem,
  virtualSocialNavItem,
  slidoNavItem,
];

function App() {
  const [mainStyle, setMainStyle] = useState({});
  const [rotate, setRotate] = useState(false);

  const handleMouseMove = (e: MouseEvent) => {
    const x = e.clientX;
    const y = e.clientY;
    const rotX = (y - window.innerHeight / 2) / (window.innerHeight / 2);
    const rotY = (x - window.innerWidth / 2) / (window.innerWidth / 2);
    const dist = (Math.abs(rotX) + Math.abs(rotY)) * 65;
    setMainStyle({
      transform: `rotate3d(${-rotX}, ${rotY}, 0, ${dist}deg)`,
    });
  };

  useEffect(() => {
    if (rotate) {
      window.addEventListener("mousemove", handleMouseMove);
      return () => window.removeEventListener("mousemove", handleMouseMove);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      setMainStyle({});
    }
  }, [rotate]);

  return (
    <Router>
      <nav>
        {NAV_ITEMS.map(({ title, url, external }, i) => (
          <>
            {i > 0 ? "| " : ""}
            {external ? (
              <a href={url}>{title}</a>
            ) : (
              <Link to={url}>{title} </Link>
            )}{" "}
          </>
        ))}
      </nav>
      <main style={mainStyle}>
        <Switch>
          <Route exact path="/">
            <Home
              navItems={HOME_NAV_ITEMS}
              onLogoClick={() => setRotate(!rotate)}
            />
          </Route>
          <Route exact path="/about">
            <About />
          </Route>
          <Route exact path="/agenda">
            <Agenda />
          </Route>
          <Route exact path="/poster">
            <Poster />
          </Route>
          <Route exact path="/virtual-social">
            <VirtualSocial />
          </Route>
          <Route exact path="/breakouts">
            <Breakouts />
          </Route>
        </Switch>
      </main>
      <div className="rblogo-container">
        <img className="rblogo-img" src={rblogo} alt="RB Logo" />
      </div>
    </Router>
  );
}

export default App;
